import axios from "axios";
import { API_ENDPOINT } from "../../../config";

export class DataPresenter {
  async initJobylonJobs(companyId: string) {
    try {
      const result: {
        created: number;
        company_id: string;
      } = (await axios.get(`${API_ENDPOINT}/jobylon/create-all/${companyId}`))
        .data;
      return result;
    } catch (error) {
      throw error;
    }
  }
}
