import axios from "axios";
import { WEB_APP_ENDPOINT } from "../../../config";
import {
  GetCompaniesResponse,
  GetContactsResponse,
  GridImageConfigs,
  MembrainLabel,
  MembrainLabelType,
} from "../types";

export class DataPresenter {
  async getContacts(labelId: string, from: number, to: number) {
    try {
      const result: GetContactsResponse = (
        await axios.get(`${WEB_APP_ENDPOINT}/membrain/contacts`, {
          params: {
            labelId: labelId,
            from: from,
            to: to,
          },
        })
      ).data;

      const contactsWithLinkedin = result.items.filter(
        (contact) => Boolean(contact.LinkedIn) === true
      );
      const contactsWithoutLinkedin = result.items.filter(
        (contact) => Boolean(contact.LinkedIn) === false
      );

      return {
        ...result,
        contactsWithLinkedin,
        contactsWithoutLinkedin,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getCompanies(labelId: string, from: number, to: number) {
    try {
      const result: GetCompaniesResponse = (
        await axios.get(`${WEB_APP_ENDPOINT}/membrain/companies`, {
          params: {
            labelId: labelId,
            from: from,
            to: to,
          },
        })
      ).data;

      const companiesWithLinkedin = result.items.filter(
        (contact) => Boolean(contact.LinkedIn) === true
      );
      const companiesWithoutLinkedin = result.items.filter(
        (contact) => Boolean(contact.LinkedIn) === false
      );

      return {
        ...result,
        companiesWithLinkedin,
        companiesWithoutLinkedin,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getLabels(type: MembrainLabelType) {
    try {
      const result: MembrainLabel[] = (
        await axios.get(`${WEB_APP_ENDPOINT}/membrain/labels`, {
          params: {
            type: type,
          },
        })
      ).data;

      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getLinkedinProfileImageLinks(
    linkedinLinks: string[],
    sessionCookieValue: string
  ) {
    try {
      const result: {
        lowResImageLinks: string[];
      } = (
        await axios.get(`${WEB_APP_ENDPOINT}/linkedin/profiles`, {
          params: {
            linkedinLinks: linkedinLinks.map((link) => link.trim()).join(","),
            sessionCookieValue: sessionCookieValue,
          },
          timeout: 540000,
        })
      ).data;

      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getGridImage(
    args: {
      imageLinks: string[];
      type: MembrainLabelType;
    } & GridImageConfigs
  ) {
    try {
      const result: {
        outputImageUrl: string;
        outputDownloadUrl: string;
      } = (
        await axios.post(
          `${WEB_APP_ENDPOINT}/image-processor/grid-image`,
          args,
          {
            timeout: 300000,
          }
        )
      ).data;

      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
