import { initializeApp } from "firebase/app";
import { FirebaseAuth } from "./auth/firebaseAuth";
import { getFirestore } from "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyAZSk1JLEsg5URKAYdC1b3V-FQhwHZCfWc",
  authDomain: "saasiest-jobs-to-teamtailor.firebaseapp.com",
  projectId: "saasiest-jobs-to-teamtailor",
  storageBucket: "saasiest-jobs-to-teamtailor.appspot.com",
  messagingSenderId: "997002847928",
  appId: "1:997002847928:web:16f7e651cd803ca709764d",
  measurementId: "G-7VBGNZWKS8",
};

export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = new FirebaseAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
