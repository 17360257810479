import {
  Col,
  Input,
  Row,
  Button,
  Progress,
  Form,
  Image,
} from "@pankod/refine-antd";
import { useNotification } from "@pankod/refine-core";
import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { GridGenerationCore } from "./core";
import { MembrainLabelType } from "./types";

interface FormFields {
  cellHeight: number;
  cellWidth: number;
  cols: number;
  rows: number;
  outputHeight: number;
  outputWidth: number;
}

export const CreateGrid: React.FC<{
  linkedinLinks: string[];
  type: MembrainLabelType;
}> = ({ linkedinLinks, type }) => {
  const gridGenerationCore = new GridGenerationCore();
  const { open } = useNotification();
  const [form] = Form.useForm<FormFields>();
  const [generating, setGenerating] = useState<boolean>(false);
  const [generationProgress, setGenerationProgress] = useState<number>(0);
  const [linkedinSession, setLinkedinSession] = useState<string | null>(
    localStorage.getItem("linkedinSession")
  );
  const [outputImageUrl, setOutputImageUrl] = useState<string>();
  const [outputDownloadUrl, setOutputDownloadUrl] = useState<string>();
  const _onFinish = async (value: any) => {
    const estimationTimer = estimateLoadingTime();
    try {
      // console.debug(value);
      const _linkedinSession = value.linkedinSession;
      if (_linkedinSession) {
        setGenerating(true);
        localStorage.setItem("linkedinSession", _linkedinSession);
        setLinkedinSession(_linkedinSession);
        const images = await gridGenerationCore.generateGridImage(
          linkedinLinks,
          _linkedinSession,
          {
            height: parseInt(value.cellHeight),
            width: parseInt(value.cellWidth),
            columns: parseInt(value.cols),
            borderColor: value.borderColor,
            border: parseInt(value.border),
          },
          type
        );
        open?.({
          message: "Image generation completed",
          type: "success",
          key: "image-gen-success",
        });
        setOutputImageUrl(images.outputImageUrl);
        setOutputDownloadUrl(images.outputDownloadUrl);
        setGenerationProgress(100);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        open?.({
          message: error.response?.data?.message || "An unknown error occurred",
          type: "error",
          key: "error-notification",
        });
      }
    } finally {
      setGenerating(false);
      setGenerationProgress(0);
      clearInterval(estimationTimer);
    }
  };

  const estimateLoadingTime = () => {
    const totalLinks = linkedinLinks.length;
    const secondForEach = 6;
    const totalTime = totalLinks * secondForEach + 10 + 30;
    let elapsedTime = 0;

    return setInterval(() => {
      if (elapsedTime < totalTime) {
        const percentage = (elapsedTime / totalTime) * 100;
        setGenerationProgress(parseInt(percentage.toString()));
        elapsedTime += 5;
      }
    }, 5000);
  };

  const _onChange = () => {
    const formFields = form.getFieldsValue();
    const totalImages = linkedinLinks.length;
    const cellHeight = parseInt(formFields.cellHeight.toString());
    const cellWidth = parseInt(formFields.cellWidth.toString());
    const cols = parseInt(formFields.cols.toString());
    const rows = Math.ceil(totalImages / cols);
    const finalHeight = cellHeight * rows;
    const finalWidth = cellWidth * cols;

    form.setFields([
      {
        name: "outputHeight",
        value: finalHeight,
      },
      {
        name: "outputWidth",
        value: finalWidth,
      },
      {
        name: "rows",
        value: rows,
      },
    ]);
  };

  useEffect(() => {
    _onChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Col span={24}>
      <Form
        form={form}
        onFinish={_onFinish}
        layout="vertical"
        onChange={_onChange}
        initialValues={{
          cellHeight: 100,
          cellWidth: 100,
          cols: 10,
          border: 0,
          borderColor: "#ffffff",
          linkedinSession: linkedinSession,
        }}
      >
        <Form.Item
          label="Linkedin session"
          name="linkedinSession"
          rules={[
            {
              required: true,
              type: "string",
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Cell Height (px)"
          name="cellHeight"
          rules={[
            {
              required: true,
              type: "integer",
              transform: (value) => parseInt(value),
              min: 10,
              max: 2000,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Cell Width (px)"
          name="cellWidth"
          rules={[
            {
              required: true,
              type: "integer",
              transform: (value) => parseInt(value),
              min: 10,
              max: 2000,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Columns"
          name="cols"
          rules={[
            {
              required: true,
              type: "integer",
              transform: (value) => parseInt(value),
              min: 1,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Border Width"
          name="border"
          rules={[
            {
              required: true,
              type: "integer",
              transform: (value) => parseInt(value),
              min: 0,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Border Color"
          name="borderColor"
          rules={[
            {
              required: true,
              type: "string",
            },
          ]}
        >
          <Input type="color" />
        </Form.Item>
        <Form.Item
          label="Rows"
          name="rows"
          rules={[
            {
              required: true,
              type: "number",
              message: "Number of Row is required",
            },
          ]}
        >
          <Input disabled type="number" />
        </Form.Item>
        <Form.Item
          label="Final Photo Height (px)"
          name="outputHeight"
          rules={[
            {
              required: true,
              type: "number",
              message: "Final Photo Height is required",
            },
          ]}
        >
          <Input disabled type="number" />
        </Form.Item>
        <Form.Item
          label="Final Photo Width (px)"
          name="outputWidth"
          rules={[
            {
              required: true,
              type: "number",
              message: "Final Photo Width is required",
            },
          ]}
        >
          <Input disabled type="number" />
        </Form.Item>
        {linkedinLinks.length !== 0 && (
          <Row justify="end">
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={generating}>
                Generate
              </Button>
            </Form.Item>
          </Row>
        )}
      </Form>
      {generating && (
        <Row>
          <Progress
            strokeLinecap="butt"
            percent={generationProgress}
            status="active"
          />
        </Row>
      )}
      {outputImageUrl && (
        <>
          <Row justify="center">
            <Image
              style={{ marginTop: 24, maxHeight: 500 }}
              src={outputImageUrl}
            />
          </Row>
          <Row
            justify="end"
            style={{
              marginTop: 24,
            }}
          >
            <Button type="primary" href={outputDownloadUrl} target="__blank">
              Download
            </Button>
          </Row>
        </>
      )}
    </Col>
  );
};
