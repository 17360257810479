import { Button, Form, Input, List, Select } from "@pankod/refine-antd";
import useMediaQuery from "use-media-antd-query";
import { useState, useEffect } from "react";
import { DataPresenter } from "./data";
import { ItemsCard } from "./ItemsCard";
import { MembrainCompany } from "./types";
export const CompanyGrid = () => {
  return (
    <List title="Company Grid Generation">
      <GenerateLinkedinLinks />
    </List>
  );
};

const GenerateLinkedinLinks = () => {
  const screenSize = useMediaQuery();
  const dataPresenter = new DataPresenter();
  const [labelSelectProps, setLabelSelectProps] =
    useState<{ label: string; value: string }[]>();
  const [loadingContacts, setLoadingCompanies] = useState<boolean>();
  const [companies, setCompanies] = useState<{
    companiesWithLinkedin: MembrainCompany[];
    companiesWithoutLinkedin: MembrainCompany[];
    count: string;
    from: string;
    to: string;
    items: MembrainCompany[];
  }>();
  const [companiesWithLinkedin, setCompaniesWithLinkedin] =
    useState<MembrainCompany[]>();
  const [companiesWithoutLinkedin, setCompaniesWithoutLinkedin] =
    useState<MembrainCompany[]>();
  const loadLabels = async () => {
    const labels = await dataPresenter.getLabels("Company");
    const selectProps = labels.map((label) => ({
      label: label.Name,
      value: label.Id,
    }));
    setLabelSelectProps(selectProps);
  };

  const loadCompanies = async (
    _selectedLabelId: string,
    _from: number,
    _to: number
  ) => {
    try {
      if (_selectedLabelId && _from && _to) {
        setLoadingCompanies(true);
        const companies = await dataPresenter.getCompanies(
          _selectedLabelId,
          _from - 1,
          _to
        );
        setCompanies(companies);
        setCompaniesWithLinkedin(companies.companiesWithLinkedin);
        setCompaniesWithoutLinkedin(companies.companiesWithoutLinkedin);
        setLoadingCompanies(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingCompanies(false);
    }
  };

  useEffect(() => {
    loadLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Form
        layout={
          screenSize === "sm" || screenSize === "xs" ? "vertical" : "inline"
        }
        initialValues={{
          from: 1,
          to: 50,
        }}
        onFinish={(values) => {
          const _labelId = values.label;
          const _from = values.from;
          const _to = values.to;
          loadCompanies(_labelId, _from, _to);
        }}
      >
        <Form.Item
          style={{ flex: 2 }}
          name="label"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={
              labelSelectProps ? "Select Label" : "Loading Labels..."
            }
            options={labelSelectProps ?? []}
          ></Select>
        </Form.Item>
        <Form.Item
          name="from"
          style={{ flex: 1 }}
          rules={[
            {
              required: true,
              transform: (value) => parseInt(value),
              type: "number",
              min: 1,
            },
          ]}
        >
          <Input placeholder="From" type="number"></Input>
        </Form.Item>
        <Form.Item
          name="to"
          style={{ flex: 1 }}
          rules={[
            {
              required: true,
              transform: (value) => parseInt(value),
              type: "number",
              min: 1,
            },
          ]}
        >
          <Input placeholder="To" type="number"></Input>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadingContacts}>
            Proceed
          </Button>
        </Form.Item>
      </Form>

      {companies && companiesWithLinkedin && companiesWithoutLinkedin && (
        <ItemsCard
          itemsWithLink={companiesWithLinkedin?.map((_company) => ({
            title: `${_company.Name}`,
            link: _company.LinkedIn,
          }))}
          itemsWithoutLink={companiesWithoutLinkedin.map((_company) => ({
            title: `${_company.Name}`,
            description: _company.Homepage,
          }))}
          totalAvailableItems={companies.count}
          heading="Companies with linkedin"
          type="Company"
        />
      )}
    </>
  );
};
