import { Refine } from "@pankod/refine-core";
import * as refineAntd from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import { firebaseAuth } from "./firebaseConfig";
import { TableOutlined } from "@ant-design/icons";
import { CompanyGrid } from "./pages/gridGeneration/CompanyGrid";
import { ContactGrid } from "./pages/gridGeneration/ContactGrid";
import { Jobylon } from "./pages/jobBoard/Jobylon";

const CustomLoginPage = () => {
  return (
    <refineAntd.AuthPage
      registerLink={false}
      wrapperProps={{
        style: {
          background: "#16213E",
        },
      }}
    />
  );
};

function App() {
  const { Link } = routerProvider;

  return (
    <Refine
      notificationProvider={refineAntd.notificationProvider}
      Layout={refineAntd.Layout}
      ReadyPage={refineAntd.ReadyPage}
      catchAll={<refineAntd.ErrorComponent />}
      authProvider={firebaseAuth.getAuthProvider()}
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            path: "/reset-password",
            element: (
              <refineAntd.AuthPage
                type="resetPassword"
                wrapperProps={{
                  style: {
                    background: "#16213E",
                  },
                }}
              />
            ),
          },
        ],
      }}
      dataProvider={dataProvider("https://api.fake-rest.refine.dev")}
      // authProvider={firebaseAuth.getAuthProvider()}
      LoginPage={CustomLoginPage}
      resources={[
        {
          name: "gridGeneration",
          icon: <TableOutlined />,
          options: {
            label: "Grid Generation",
          },
        },
        {
          name: "company",
          parentName: "gridGeneration",
          list: CompanyGrid,
        },
        {
          name: "contact",
          parentName: "gridGeneration",
          list: ContactGrid,
        },
        {
          name: "jobBoard",
        },
        {
          name: "jobylon",
          parentName: "jobBoard",
          options: {
            label: "Jobylon",
          },
          list: Jobylon,
        },
      ]}
      Header={refineAntd.Header}
      Title={({ collapsed }) => (
        <Link to="/" style={{ float: "left", marginRight: "10px" }}>
          <img
            src="/assets/logo.png"
            alt="SaaS Nordic"
            width={collapsed ? "80px" : "200px"}
          />
        </Link>
      )}
    />
  );
}

export default App;
