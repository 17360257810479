import { Alert, Button, Form, Input, List } from "@pankod/refine-antd";
import { useNotification } from "@pankod/refine-core";
import { AxiosError } from "axios";
import { useState } from "react";
import { DataPresenter } from "./data";

export const Jobylon = () => {
  const dataPresenter = new DataPresenter();
  const [loading, setLoading] = useState<boolean>();
  const [totalJobCreated, setTotalJobCreated] = useState<number | null>(null);
  const { open } = useNotification();

  const initJobs = async (companyId: string) => {
    try {
      setLoading(true);
      setTotalJobCreated(null);
      const result = await dataPresenter.initJobylonJobs(companyId);
      setTotalJobCreated(result.created);
      open?.({
        message: "Job initialized",
        type: "success",
        key: "init-success",
      });
    } catch (error) {
      console.log("catch");
      let message = "An unknown error occurred";
      console.error(error);
      if (error instanceof AxiosError) {
        message = error.response?.data?.message ?? error.message ?? message;
      }
      open?.({
        message: message,
        type: "error",
        key: "init-error",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <List title="Jobylon Job Initialization">
      <Form
        layout="inline"
        onFinish={(values) => {
          const companyId = values.companyId;
          initJobs(companyId);
        }}
      >
        <Form.Item
          name={"companyId"}
          style={{ flex: 1 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="string" placeholder="Jobylon Company Id"></Input>
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Initialize
        </Button>
      </Form>
      {totalJobCreated !== null && (
        <Alert
          message={`Total jobs created: ${totalJobCreated}`}
          style={{
            marginTop: 24,
          }}
        ></Alert>
      )}
    </List>
  );
};
