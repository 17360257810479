import { Row, Spin } from "@pankod/refine-antd";
import axios from "axios";
import { useEffect, useState } from "react";
import App from "./App";
import { FirebaseAuth } from "./auth/firebaseAuth";
import { firebaseApp } from "./firebaseConfig";

export const Loading = () => {
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    FirebaseAuth.init(firebaseApp, async (user) => {
      if (user) {
        const token = await user.getIdToken(true);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
      setLoading(false);
    });
  }, []);
  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Spin
          size="large"
          style={{
            verticalAlign: "middle",
          }}
        />
      </Row>
    );
  }
  return <App />;
};
