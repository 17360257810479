import { DataPresenter } from "../data";
import { GridImageConfigs, MembrainLabelType } from "../types";

export class GridGenerationCore {
  private dataPresenter = new DataPresenter();
  async generateGridImage(
    linkedinIds: string[],
    sessionCookieValue: string,
    gridImageConfigs: GridImageConfigs,
    type: MembrainLabelType
  ) {
    //create chunk of linkedin links of 30 each

    const chunks: string[][] = [];
    while (linkedinIds.length > 0) {
      const chunk = linkedinIds.splice(0, 50);
      chunks.push(chunk);
    }

    const allLowResImageLinks: string[] = [];
    for (const chunk of chunks) {
      const profiles = await this.dataPresenter.getLinkedinProfileImageLinks(
        chunk,
        sessionCookieValue
      );
      allLowResImageLinks.push(...profiles.lowResImageLinks);
    }
    console.log("Fetched all linkedin Profile image url");
    console.log(allLowResImageLinks);

    // allResposne.forEach((value) => {
    //   allLowResImageLinks.push(...value.lowResImageLinks);
    // });

    const gridImage = await this.dataPresenter.getGridImage({
      ...gridImageConfigs,
      imageLinks: allLowResImageLinks,
      type: type,
    });

    return {
      outputImageUrl: gridImage.outputImageUrl,
      outputDownloadUrl: gridImage.outputDownloadUrl,
    };
  }
}
