import { Button, Form, Input, List, Select } from "@pankod/refine-antd";
import { useState, useEffect } from "react";
import useMediaQuery from "use-media-antd-query";
import { DataPresenter } from "./data";
import { MembrainContact } from "./types";
import { ItemsCard } from "./ItemsCard";

export const ContactGrid = () => {
  return (
    <List title="Contact Grid Generation">
      <GenerateLinkedinLinks />
    </List>
  );
};

const GenerateLinkedinLinks = () => {
  const screenSize = useMediaQuery();
  const dataPresenter = new DataPresenter();
  const [labelSelectProps, setLabelSelectProps] =
    useState<{ label: string; value: string }[]>();
  const [loadingContacts, setLoadingContacts] = useState<boolean>();
  const [contacts, setContacts] = useState<{
    contactsWithLinkedin: MembrainContact[];
    contactsWithoutLinkedin: MembrainContact[];
    count: string;
    from: string;
    to: string;
    items: MembrainContact[];
  }>();
  const [contactsWithLinkedin, setContactsWithLinkedin] =
    useState<MembrainContact[]>();
  const [contactsWithoutLinkedin, setContactsWithoutLinkedin] =
    useState<MembrainContact[]>();
  const loadLabels = async () => {
    const labels = await dataPresenter.getLabels("Contact");
    const selectProps = labels.map((label) => ({
      label: label.Name,
      value: label.Id,
    }));
    setLabelSelectProps(selectProps);
  };

  const loadContacts = async (
    _selectedLabelId: string,
    _from: number,
    _to: number
  ) => {
    try {
      if (_selectedLabelId && _from && _to) {
        setLoadingContacts(true);
        const contacts = await dataPresenter.getContacts(
          _selectedLabelId,
          _from - 1,
          _to
        );
        setContacts(contacts);
        setContactsWithLinkedin(contacts.contactsWithLinkedin);
        setContactsWithoutLinkedin(contacts.contactsWithoutLinkedin);
        setLoadingContacts(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingContacts(false);
    }
  };

  useEffect(() => {
    loadLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Form
        layout={
          screenSize === "sm" || screenSize === "xs" ? "vertical" : "inline"
        }
        initialValues={{
          from: 1,
          to: 50,
        }}
        onFinish={(values) => {
          const _labelId = values.label;
          const _from = values.from;
          const _to = values.to;
          loadContacts(_labelId, _from, _to);
        }}
      >
        <Form.Item
          style={{ flex: 2 }}
          name="label"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={
              labelSelectProps ? "Select Label" : "Loading Labels..."
            }
            options={labelSelectProps ?? []}
          ></Select>
        </Form.Item>
        <Form.Item
          name="from"
          style={{ flex: 1 }}
          rules={[
            {
              required: true,
              transform: (value) => parseInt(value),
              type: "number",
              min: 1,
            },
          ]}
        >
          <Input placeholder="From" type="number"></Input>
        </Form.Item>
        <Form.Item
          name="to"
          style={{ flex: 1 }}
          rules={[
            {
              required: true,
              transform: (value) => parseInt(value),
              type: "number",
              min: 1,
            },
          ]}
        >
          <Input placeholder="To" type="number"></Input>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadingContacts}>
            Proceed
          </Button>
        </Form.Item>
      </Form>

      {contacts && contactsWithLinkedin && contactsWithoutLinkedin && (
        <ItemsCard
          itemsWithLink={contactsWithLinkedin.map((_contact) => ({
            title: `${_contact.Name} | ${_contact.CompanyName}`,
            link: _contact.LinkedIn,
          }))}
          itemsWithoutLink={contactsWithoutLinkedin.map((_contact) => ({
            title: `${_contact.Name} | ${_contact.CompanyName}`,
            description: _contact.EmailAddress,
          }))}
          totalAvailableItems={contacts.count}
          heading="Contacts with linkedin"
          type="Contact"
        />
      )}
    </>
  );
};
