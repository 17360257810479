import { Card, Row, Typography, Col, AntdList } from "@pankod/refine-antd";
import { CreateGrid } from "./CreateGrid";
import { MembrainLabelType } from "./types";

export interface ItemWithLink {
  title: string;
  link: string;
}

export interface ItemWithoutLink {
  title: string;
  description: string;
}
export const ItemsCard: React.FC<{
  itemsWithLink: ItemWithLink[];
  itemsWithoutLink: ItemWithoutLink[];
  /**
   * Number of total items available in database
   *
   * Note: Fetched items can be less that total available items available in database
   */
  totalAvailableItems: string;
  heading: string;
  type: MembrainLabelType;
}> = ({
  itemsWithLink,
  totalAvailableItems,
  itemsWithoutLink,
  heading,
  type,
}) => {
  const linkedinLinks = itemsWithLink.map((item) => item.link);
  return (
    <Card
      style={{
        marginTop: 50,
      }}
    >
      <Row style={{ marginBottom: 24, flex: 1 }}>
        <Typography.Title level={4} style={{ marginBottom: 24 }}>
          Grid Generation Config
        </Typography.Title>
        <CreateGrid linkedinLinks={linkedinLinks} type={type} />
      </Row>
      <Row gutter={[8, 8]} justify="space-between">
        <Col md={24} lg={12} flex={1}>
          <Card
            title={`${heading} - ${itemsWithLink.length}/${totalAvailableItems}`}
          >
            <AntdList
              itemLayout="horizontal"
              dataSource={itemsWithLink}
              renderItem={(item: ItemWithLink) => (
                <AntdList.Item>
                  <AntdList.Item.Meta
                    title={item.title}
                    description={
                      <Typography.Link href={item.link} target="_blank">
                        {item.link}
                      </Typography.Link>
                    }
                  />
                </AntdList.Item>
              )}
            />
          </Card>
        </Col>
        <Col md={24} lg={12} flex={1}>
          <Card
            title={`${heading} - ${itemsWithoutLink.length}/${totalAvailableItems}`}
          >
            <AntdList
              itemLayout="horizontal"
              dataSource={itemsWithoutLink}
              renderItem={(item: ItemWithoutLink) => (
                <AntdList.Item>
                  <AntdList.Item.Meta
                    title={item.title}
                    description={
                      <Typography.Text>{item.description}</Typography.Text>
                    }
                  />
                </AntdList.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </Card>
  );
};
